/*
 * Copyright 2024 LLC Campus.
 */

import React, { useEffect, useState } from 'react'
import { fetchConfirmationToken, fetchPromocodeCatalog } from '../services/api'
import PromocodeCard from './PromocodeCard'
import '../styles/PromocodeCatalog.css'
import { openYooKassaWidget } from '../yookassa'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function PromocodeCatalog () {
  const [promocodes, setPromocodes] = useState([])
  const [email, setEmail] = useState('')
  const [selectedPromocodeId, setSelectedPromocodeId] = useState(null)
  const [isWidgetOpen, setIsWidgetOpen] = useState(false)
  const [isEmailValid, setIsEmailValid] = useState(true)
  const [hasErrorToast, setHasErrorToast] = useState(false)

  useEffect(() => {
    let isMounted = true
    const fetchData = async () => {
      try {
        const data = await fetchPromocodeCatalog()
        if (isMounted) {
          setPromocodes(data.items)
        }
      } catch (error) {
        if (isMounted) {
          if (!hasErrorToast) {
            toast.error(error.message)
            setHasErrorToast(true)
          }
        }
      }
    };

    fetchData()

    return () => {
      isMounted = false
    };
  }, [hasErrorToast])

  const handleEmailChange = (event) => {
    setEmail(event.target.value)
    setIsEmailValid(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(event.target.value))
  }

  const handleCardClick = (promocodeId) => {
    setSelectedPromocodeId(promocodeId)
  }

  const handlePurchaseClick = async () => {
    const selectedPromocode = promocodes.find(promocode => promocode._id === selectedPromocodeId)

    if (selectedPromocode) {
      try {
        const data = await fetchConfirmationToken(selectedPromocodeId, email);

        if (data.confirmationToken && data.transactionId) {
          setIsWidgetOpen(true);
          openYooKassaWidget(data.confirmationToken, selectedPromocode, data.transactionId);
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
  }

  return (
    <div>
      <div id="yookassa-container" className={isWidgetOpen ? '' : 'hidden'}></div>
      <div className={`promocode-catalog ${isWidgetOpen ? 'hidden' : ''}`}>
        <div className="promocode-catalog-header">
          <div className="promocode-catalog-header-title">Промокоды от Кампус</div>
          <ul className="promocode-catalog-header-list">
            <li>отключение рекламы в приложении</li>
            <li>отзывы и посты до модерации</li>
          </ul>
        </div>
        <div className="promocode-grid">
          <ToastContainer />
          {promocodes.length > 0 ? (
            promocodes.map(promocode => (
              <PromocodeCard
                key={promocode._id}
                promocode={promocode}
                isSelected={selectedPromocodeId === promocode._id}
                onClick={() => handleCardClick(promocode._id)}
              />
            ))
          ) : (
            <p className="no-promocodes-message">Нет доступных для покупки промокодов</p>
          )}
        </div>
        <div className="email-input-container">
          <input
            type="email"
            placeholder="Введите email"
            value={email}
            onChange={handleEmailChange}
            className={`email-input ${!isEmailValid ? 'invalid' : ''}`}
          />
          {!isEmailValid && <div className="error-message">Введите корректный email</div>}
          <button
            onClick={handlePurchaseClick}
            className={`purchase-button ${!selectedPromocodeId ? 'disabled' : ''}`}
            disabled={!selectedPromocodeId}
          >
            Перейти к оплате
          </button>
          <div className="support-text">
            <p>Возникли проблемы или есть вопросы?</p>
            <p>Если у вас возникли проблемы с оплатой или есть какие-либо вопросы, пожалуйста, свяжитесь с нами через
              один из доступных каналов:</p>
            <ul>
              <li>E-mail: <a href="mailto:dewish.ru@gmail.com">dewish.ru@gmail.com</a></li>
              <li>Чат: в мобильном приложении</li>
            </ul>
            <p>Мы готовы помочь вам в любое время!</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PromocodeCatalog
