/*
 * Copyright 2024 LLC Campus.
 */
import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { checkStatus } from '../services/api'
import '../styles/SuccessPage.css'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { MoonLoader } from 'react-spinners'

const SuccessPage = () => {
  const location = useLocation()
  const transactionId = new URLSearchParams(location.search).get('transactionId')
  const [code, setCode] = useState(null)
  const [description, setDescription] = useState('')
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (transactionId) {
      const checkResult = async () => {
        try {
          const response = await checkStatus(transactionId)
          const code = response.code
          if (code !== undefined) {
            setCode(code)
            setDescription('Код будет выслан на вашу электронную почту')
            setLoading(false);
          } else {
            setTimeout(checkResult, 5000)
          }
        } catch (error) {
          toast.error(error.message)
          setError(error)
          setLoading(false)
        }
      }

      checkResult()
    }
  }, [transactionId])

  return (
    <div className="overlay">
      <ToastContainer />
      {error && (
        <div className="error-container">
          <p>Произошла ошибка при обработке платежа.</p>
          <p>Причина: {error.message}</p>
        </div>
      )}

      {!loading && !error && (
        <div className="success-container">
          <h1>Поздравляем с покупкой!</h1>
          {code ? (
            <div>
              <p>Твой промокод:</p>
              <p><strong>{code}</strong></p>
              <p>{description}</p>
            </div>
          ) : (
            <p>Код не получен, возможно, платеж еще обрабатывается. Попробуйте обновить страницу позже.</p>
          )}
          <Link to="/store" className="return-button">Вернуться в магазин</Link>
        </div>
      )}

      {loading && !error && (
        <div className="loader-container">
          <MoonLoader
            size={50}
            color="#36D7B7"
            loading={loading}
          />
          <p className="loader-text">Платеж в обработке, пожалуйста, подождите...</p>
        </div>
      )}
    </div>
  )
}

export default SuccessPage