/*
 * Copyright 2024 LLC Campus.
 */

import React from 'react'
import '../styles/PromocodeCard.css'
import CreditCardIcon from './CreditCardIcon'

const PromocodeCard = ({ promocode, isSelected, onClick }) => {
  return (
    <div className={`promocode-card ${isSelected ? 'selected' : ''}`} onClick={onClick}>
      <div className="promocode-card-content">
        <div className="promocode-card-header">
          <h3 className="promocode-name">{promocode.name}</h3>
          <p className="promocode-price">
            <span>{promocode.price.value} {promocode.price.currency}</span>
            <CreditCardIcon className="card-icon"/></p>
        </div>
      </div>
    </div>
  );
};

export default PromocodeCard
